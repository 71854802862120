import React, { Component, Fragment } from 'react'
import ReactModal from 'react-modal'
import * as R from 'ramda'
import { css } from 'react-emotion'
import { sizes } from '@/styles/settings/typography'
import { breakpoints } from '@/styles/helpers'
import Button from '@/components/Button/Button'
import SevenPathsOverlay from '@/components/SevenPathsOverlay/SevenPathsOverlay'
import Image from '@/components/utils/Image'
import plusIcon from '@/icons/ui-plus.svg'
import Icon from '@/components/utils/Icon'

const textStyle = css`
	font-size: ${sizes.xl.max};

	${breakpoints.md} {
		white-space: nowrap;
		font-size: ${sizes.h6.max};
		overflow: hidden;
		display: inline-block;
		position: relative;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
	}

	${breakpoints.lg} {
		font-size: ${sizes.h2.max};
	}
`

ReactModal.setAppElement('#___gatsby')

export default class SevenPathways extends Component {
	state = {
		isOpen: false
	}

	onClick = e => {
		e.preventDefault()

		const { isOpen } = this.state

		this.setState(() => ({
			isOpen: !isOpen
		}))
	}

	handleClose = () => {
		this.setState(() => ({
			isOpen: false
		}))
	}

	render() {
		const { image: tmpImage, tags, heading, text } = this.props
		const { isOpen } = this.state

		const img = tmpImage[0]

		const image = {
			...img.optimisedImage,
			title: img.title
		}

		const first2 = R.take(2, tags)
		const last2 = R.takeLast(2, tags)

		return (
			<Fragment>
				<div className="relative overflow-hidden">
					<div className="w-full absolute pin">
						<div className="dimmer dimmer--image h-full">
							<Image fluid={image} />
						</div>
					</div>

					<div className="relative px-1 pt-1 pb-2 md:px-0 md:py-2 lg:py-5 xl:pt-8 xl:pb-12 font-bold">
						<p className={textStyle}>
							<span className="hidden md:inline opacity-25" aria-hidden="true">
								{tags.map(item => item.title).join('. ')}
							</span>
						</p>
						<p className={`${textStyle} md:mb-2`}>
							<span className="hidden md:inline opacity-25" aria-hidden="true">
								{first2.map(item => item.title).join('. ')}{' '}
							</span>
							<span className="inline-block lowercase">{heading}</span>
							<span className="hidden md:inline opacity-25" aria-hidden="true">
								{' '}
								{last2.map(item => item.title).join('. ')}
							</span>
						</p>
						<p className="text-xs-fluid mt-0-75 mb-3 font-regular md:hidden leading-1-6">
							{text}
						</p>
						<div className="text-center">
							<Button onClick={this.onClick} bg={['teal', 'orange']}>
								<span className="mr-1">select</span>
								<Icon icon={plusIcon} width="0.75em" />
							</Button>
						</div>
					</div>
				</div>
				<ReactModal
					closeTimeoutMS={300}
					onRequestClose={this.handleClose}
					isOpen={isOpen}
					aria={{
						labelledby: 'overlay-title',
						describedby: 'overlay-content'
					}}
					style={{
						overlay: {
							transform: 'translateZ(0px)',
							position: 'fixed',
							background: 'none'
						},
						content: {
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							padding: 0,
							overflow: 'hidden',
							borderRadius: 0,
							background: 'none',
							border: 0
						}
					}}
				>
					<SevenPathsOverlay tags={tags} onClick={this.onClick} />
				</ReactModal>
			</Fragment>
		)
	}
}
