import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Image from '@/components/utils/Image'
import posed from 'react-pose'

const Box = posed.div({
	show: { opacity: 1, y: '50%', delay: 50, transition: { ease: 'easeOut' } },
	hide: { opacity: 1, y: '90%' }
})

const Para = posed.p({
	show: { opacity: 1 },
	hide: { opacity: 0 }
})

class HoverSection extends Component {
	state = {
		visible: false
	}

	componentDidMount = () => {
		if (typeof window !== 'undefined') {
			if (window.innerWidth <= 768) {
				this.setState({ visible: true })
			}
		}
	}

	render() {
		const { visible } = this.state
		const { title, subtitle, uri, image } = this.props

		return (
			<Link
				to={uri}
				className="relative text-center overflow-hidden flex-1 bg-black lg:bg-teal block"
				onMouseEnter={() => {
					this.setState({ visible: true })
				}}
				onMouseLeave={() => {
					this.setState({ visible: false })
				}}
			>
				<div
					className={`relative pin w-full h-full overflow-hidden trans dimmer dimmer--image dimmer--image-subtle opacity-50 ${
						visible ? 'lg:opacity-0' : 'lg:opacity-100'
					}`}
				>
					<Image fluid={image} />
				</div>
				<Box
					initialPose="show"
					className="absolute middle w-full h-full pin-b mb-2"
					pose={visible ? 'show' : 'hide'}
					key={`${title}-box`}
				>
					<h2 className="font-bold text-h6-fluid mb-1 lowercase">{title}</h2>
					{subtitle && (
						<Para
							initialPose="show"
							className="w-full text-body-fluid px-1"
							pose={visible ? 'show' : 'hide'}
							key={`${title}-para`}
						>
							{subtitle}
						</Para>
					)}
				</Box>
			</Link>
		)
	}
}

/* eslint-disable react/require-default-props */

HoverSection.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	image: PropTypes.object.isRequired,
	uri: PropTypes.string.isRequired
}

export default HoverSection
