import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BetterIcon from '@/components/utils/BetterIcon'
import trend from '@/icons/ui-trend.svg'

export default class HeadingWithIcon extends Component {
	shouldComponentUpdate = () => false

	render() {
		const { color = 'teal', text = 'Trending' } = this.props

		return (
			<div className="flex items-center mb-2-75">
				<div className={`text-${color} mr-0-75`}>
					<BetterIcon icon={trend} className="w-45 mr-0-75" />
				</div>
				<h3 className="text-h4-fluid font-bold leading-0-5 lowercase">
					{text}
				</h3>
			</div>
		)
	}
}

/* eslint-disable react/require-default-props */

HeadingWithIcon.propTypes = {
	color: PropTypes.string,
	text: PropTypes.string.isRequired
}
