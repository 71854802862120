import React, { Component, Fragment } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Image from '@/components/utils/Image'
import BigPlayPause from '@/components/utils/BigPlayPause'

export default class MediaPreview extends Component {
	shouldComponentUpdate = () => false

	render() {
		const { title, uri, duration, slug, image, tag, subtitle } = this.props

		return (
			<Fragment>
				<div className="relative hover-parent">
					<Link className="block outline-none" tabIndex="-1" to={uri}>
						<div className="dimmer">
							<Image fluid={image} />
						</div>
					</Link>
					<div className="absolute-center z-20">
						<BigPlayPause
							to={uri}
						/>
					</div>
				</div>
				<div className="px-1 py-1 md:px-0">
					<h2 className="text-lg-fluid mb-1">
						<Link
							to={uri}
							className="text-grey-light font-regular hover:text-orange focus:text-orange  outline-none trans leading-1-5"
						>
							{title}
						</Link>
						<span className="text-aluminium text-sm-fluid ml-0-5">{duration}</span>
					</h2>
					{subtitle && <p className="text-xs-fluid mb-1">{subtitle}</p>}
					<Link
						to={`/tags/${tag}`}
						className="hover:text-orange focus-box text-teal trans trans-color font-bold text-xxs-fluid outline-none"
					>
						#{tag}
					</Link>
				</div>
			</Fragment>
		)
	}
}

MediaPreview.propTypes = {
	uri: PropTypes.string.isRequired,
	tag: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	image: PropTypes.object.isRequired,
	slug: PropTypes.string.isRequired,
}
