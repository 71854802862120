import React, { Component } from 'react'
import Loadable from 'react-loadable'
import PropTypes from 'prop-types'
import Scrollchor from 'react-scrollchor'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import playIcon from '@/icons/ui-play.svg'
import questionIcon from '@/icons/ui-question-mark.svg'
import BetterIcon from '@/components/utils/BetterIcon'
import Image from '@/components/utils/Image'
import Loader from '@/components/utils/Loader'
import hasUserVisitedRecently from '@/utils/localStorage'
import once from 'lodash.once'
// import { FadeBox } from '@/animation''
// import VideoPlayer from '@/components/VideoPlayer/VideoPlayer'
// import VideoControls from '@/components/VideoControls/VideoControls'
import closeIcon from '@/icons/ui-close.svg'

ReactModal.setAppElement('#___gatsby')

const overlay = {
	backgroundColor: 'rgba(0,0,0,0.85)'
}

const inner = {
	position: 'static',
	top: 'auto',
	left: 'auto',
	right: 'auto',
	bottom: 'auto',
	border: 0,
	background: 'none',
	overflow: 'auto',
	borderRadius: 0,
	outline: 'none',
	padding: '0 5%',
	width: '100%'
}

const LoadableComponent = Loadable({
	loader: () => import('./HeroVideo'),
	loading: () => <Loader />
})

const shouldModalBeOpened = once(hasUserVisitedRecently)
class HeroHome extends Component {
	state = {
		isOpen: false,
		isUserControlled: false
	}

	timer = undefined

	// only the show sign up modal once every 14 days
	// componentDidMount = () => {
	// 	if (window) {
	// 		if (window.innerWidth > 768) {
	// 			this.timer = setTimeout(() => {
	// 				const { isUserControlled } = this.state
	// 				this.setState({
	// 					isOpen: shouldModalBeOpened('intro-video') || isUserControlled
	// 				})
	// 			}, 3000)
	// 		}
	// 	}
	// }

	componentWillUnmount = () => {
		if (window) {
			clearTimeout(this.timer)
		}
	}

	handleClose = () => {
		this.setState(() => ({
			isOpen: false,
			isUserControlled: false
		}))
	}

	render() {
		const {
			content,
			image,
			stop,
			playing,
			video,
			setAudioBarVisibility,
			shouldVideoPlay,
			setVideoPlayState
		} = this.props
		const { isOpen } = this.state

		return (
			<div className="relative overflow-hidden">
				<div className="dimmer dimmer--image absolute lg:relative w-full h-full">
					<Image
						fluid={image}
						outerWrapperClassName="h-full lg:h-auto"
						className="h-full lg:h-auto"
					/>
				</div>
				<div className="wrapper lg:absolute lg:pin lg:h-full lg:flex lg:items-center dimmer-text">
					<div className="relative z-10 pt-5 pb-2 sm:pt-7 lg:pt-3 sm:pb-4">
						<div
							className="text-h1-fluid mb-2 lg:mb-4 text-white w-20/24 md:w-13/24"
							style={{ maxWidth: 2000 }}
							dangerouslySetInnerHTML={{ __html: content }}
						/>
						<div className="flex items-center">
							<button
								type="button"
								className="text-left flex items-center font-regular mr-1 sm:mr-2 hover:text-orange focus:text-orange trans trans-color outline-none circle-animate-wrap"
								onClick={() => {
									this.setState({
										isOpen: true,
										isUserControlled: true
									})
								}}
							>
								<span className="w-45 h-45 md:w-80 md:h-80 mr-0-5 md:mr-1 inline-flex items-center justify-center border-3 rounded-circle border-orange circle-animate">
									<BetterIcon
										icon={playIcon}
										className="h-10 md:h-25 w-full text-white"
									/>
								</span>

								<span className="text-midget-fluid">
									play
									<br />
									introduction
								</span>
							</button>
							<Scrollchor
								to="#what-do-you-need"
								className="text-left flex items-center font-regular hover:text-orange focus:text-orange  trans trans-color outline-none circle-animate-wrap"
							>
								<span className="w-45 h-45 md:w-80 md:h-80 mr-0-5 md:mr-1 inline-flex items-center justify-center border-3 rounded-circle border-teal circle-animate circle-animate--teal">
									<BetterIcon
										icon={questionIcon}
										className="h-15 md:h-35 w-full"
									/>
								</span>
								<span className="text-midget-fluid">
									what do
									<br />
									you need?
								</span>
							</Scrollchor>
						</div>
					</div>
				</div>
				<ReactModal
					onRequestClose={this.handleClose}
					overlayClassName="flex items-center justify-center fixed pin"
					className="mx-auto flex items-center justify-center"
					closeTimeoutMS={300}
					isOpen={isOpen}
					style={{
						overlay,
						content: inner
					}}
				>
					<button
						onClick={e => {
							e.preventDefault()
							this.handleClose()
						}}
						type="button"
						className="text-white absolute flex items-center pin-t pin-r mt-1 mr-1 p-0-5"
					>
						<span className="mr-0-5 text-xs-fluid">close</span>
						<BetterIcon icon={closeIcon} className="w-20" />
					</button>
					<div className="mx-auto w-20/24">
						<LoadableComponent
							stop={stop}
							playing={playing}
							video={video}
							setAudioBarVisibility={setAudioBarVisibility}
							shouldVideoPlay={shouldVideoPlay}
							setVideoPlayState={setVideoPlayState}
						/>
					</div>
				</ReactModal>
			</div>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({
	player: { stop, setAudioBarVisibility, setVideoPlayState }
}) => ({
	setAudioBarVisibility,
	stop,
	setVideoPlayState
})

export default connect(
	mapState,
	mapDispatch
)(HeroHome)

HeroHome.propTypes = {
	content: PropTypes.string.isRequired,
	image: PropTypes.object.isRequired
}
