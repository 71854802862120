import React, { Component, Fragment } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Image from '@/components/utils/Image'
import BetterIcon from '@/components/utils/BetterIcon'
import rightChevron from '@/icons/ui-right-chevron.svg'

export default class ArticlePreview extends Component {
	shouldComponentUpdate = () => false

	render() {
		const {
			postDate,
			image,
			title,
			uri,
			subtitle,
			withSubTitle = false
		} = this.props
		return (
			<Fragment>
				<Link
					to={uri}
					tabIndex="-1"
					className="outline-none block relative mb-1-25 group"
				>
					<Image fluid={image} />

					<div className="bg-black-50 absolute pin w-full h-full z-20 flex items-end p-1 opacity-0 trans group-hover:opacity-100" />
				</Link>
				<time className="block text-xxs-fluid mb-0-75" dateTime={postDate}>
					{postDate}
				</time>
				<div className="mb-2">
					<h2 className="text-xl-fluid font-bold lowercase">
						<Link
							to={uri}
							className="hover:text-orange focus:text-orange outline-none trans trans-color"
						>
							{title}
						</Link>
					</h2>

					{withSubTitle &&
						subtitle && <p className="text-xs-fluid mt-1">{subtitle}</p>}
				</div>
				<Link
					className="text-sm-fluid font-bold hover:text-orange focus:text-orange outline-none trans trans-color inline-flex items-center"
					to={uri}
				>
					<span className="mr-0-5">read</span>
					<span className="text-teal">
						<BetterIcon icon={rightChevron} className="w-10" />
					</span>
				</Link>
			</Fragment>
		)
	}
}

ArticlePreview.propTypes = {
	postDate: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	uri: PropTypes.string.isRequired,
	image: PropTypes.object.isRequired
}
