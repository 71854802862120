import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { graphql, Link } from 'gatsby'
import SEO from '@/components/SEO/SEO'
import * as R from 'ramda'
/* eslint-disable no-unused-vars */
import {
	optimisedImage,
	optimisedHero,
	relatedBlog,
	relatedAudio,
	relatedVideo,
	previewThumb
} from '@/queries/'
/* eslint-enable */
import HeroHome from '@/components/HeroHome/HeroHome'
import LatestContent from '@/components/LatestContent/LatestContent'
import HeadingWithIcon from '@/components/HeadingWithIcon/HeadingWithIcon'
import PostThumb from '@/components/PostThumb/PostThumb'
import HoverSection from '@/components/HoverSection/HoverSection'
import SevenPathways from '@/components/SevenPathways/SevenPathways'
// import Fieldnotes from '@/components/Fieldnotes/Fieldnotes'
// import TalkToUs from '@/components/TalkToUs/TalkToUs'
import RequestASong from '@/components/RequestASong/RequestASong'
import MediaPreview from '@/components/MediaPreview/MediaPreview'
import ArticlePreview from '@/components/ArticlePreview/ArticlePreview'
import Slide from '@/components/Slide/Slide'
import Image from '@/components/utils/Image'
import BetterIcon from '@/components/utils/BetterIcon'
import rightChevron from '@/icons/ui-right-chevron.svg'

import {
	prepItem,
	getSingleFromArray,
	pluckPrepItem,
	getSeoImage
} from '@/utils'
import { slugify } from '@/utils/strings'

import { setupPage } from '@/utils/common'
import housingIcon from '@/icons/housing.svg'
import benefitsIcon from '@/icons/benefits.svg'
import addictionIcon from '@/icons/addiction.svg'
import employmentIcon from '@/icons/employment.svg'
import familyIcon from '@/icons/family.svg'
import moneyIcon from '@/icons/money.svg'
import healthIcon from '@/icons/health.svg'
import twitterIcon from '@/icons/ui-twitter.svg'
import instagramIcon from '@/icons/ui-instagram.svg'
import facebookIcon from '@/icons/ui-facebook.svg'

const icons = {
	housing: housingIcon,
	money: moneyIcon,
	benefits: benefitsIcon,
	employment: employmentIcon,
	addiction: addictionIcon,
	health: healthIcon,
	family: familyIcon
}

class IndexPage extends Component {
	constructor(props) {
		super(props)

		setupPage(props)
	}

	render() {
		const {
			data: {
				home: { entry: home },
				inspiration: { entry: inspiration },
				advice: { entry: advice },
				organisation: { entry: organisation },
				blog: { entries: tmpBlog },
				weare: tmpWeAre,
				request: tmpRequest,
				globals: {
					globals: {
						// fieldnotes: tmpField,
						talkToUs: tmpTalk,
						requestASong: tmpReq,
						pathways: tmpPath
					}
				}
			},
			location,
			setVideoPlayState,
			setAudioPlayState,
			togglePlay,
			setTrack,
			setAudioBarVisibility,
			setInUse
		} = this.props

		const {
			title,
			body,
			latest: tmpLatest,
			trending: tmpTrending,
			selectedArticles: tmpSelectedArticles,
			backgroundVideo,
			seo
		} = pluckPrepItem([home])

		const video = backgroundVideo[0].url
		// const fieldnotes = pluckPrepItem([tmpField])
		const talkToUs = pluckPrepItem([tmpTalk])
		const requestASong = pluckPrepItem([tmpReq])
		const tags = R.over(
			R.lensProp('tags'),
			R.map(({ title, id }) => ({
				title,
				id,
				icon: icons[title],
				uri: `/tags/${slugify(title)}/`
			}))
		)(tmpPath)

		const latest = pluckPrepItem(tmpLatest)
		const trending = prepItem(tmpTrending)
		const threex = prepItem([inspiration, advice, organisation])
		const weare = getSingleFromArray(tmpWeAre)
		const request = getSingleFromArray(tmpRequest)
		const blog = R.compose(
			R.take(3),
			prepItem
		)([...tmpSelectedArticles, ...tmpBlog])

		const { title: heroAlt, optimisedHero } = home.heroImage[0]

		const hero = {
			title: heroAlt,
			...optimisedHero
		}

		const {
			socialMetaTitle,
			socialMetaDescription,
			seoTitle,
			seoDescription
		} = home

		return (
			<Fragment>
				<SEO
					title={seoTitle || home.title}
					description={seoDescription}
					socialTitle={socialMetaTitle || home.title}
					socialDesc={socialMetaDescription}
					url={location.href}
				/>
				<HeroHome content={body} image={hero} video={video} />

				<LatestContent
					item={latest}
					type={latest.__typename}
					title={latest.title}
					subtitle={latest.subtitle}
					uri={latest.uri}
					image={latest.image}
					tags={latest.tags}
					duration={latest.duration}
					mediaSource={latest.mediaSource}
					setVideoPlayState={setVideoPlayState}
					setAudioPlayState={setAudioPlayState}
					togglePlay={togglePlay}
					setTrack={setTrack}
					setAudioBarVisibility={setAudioBarVisibility}
					setInUse={setInUse}
				/>

				<div className="wrapper  pb-2 md:pb-3 lg:pb-4 xl:pb-6">
					<HeadingWithIcon text="trending" color="orange" />
					<div className="overflow-hidden">
						<div className="w-18/24 md:w-full relative">
							<Slide
								content={trending}
								slidesToShow={3}
								wrapperClassName="md:w-21/24 lg:w-auto"
								btnWrapperClassName="lg:hidden"
								render={item => <PostThumb {...item} withSubTitle />}
							/>
						</div>
					</div>
				</div>

				<div className="lg:flex border-t border-grey-brd lg:border-0">
					{threex.map(item => (
						<Fragment key={item.id}>
							<div className="w-full lg:w-8/24">
								<HoverSection {...item} />
							</div>
							{/* <Link
								to={item.uri}
								className="border-b block border-grey-brd lg:hidden py-1 text-center text-h4-fluid"
							>
								{item.title}
							</Link> */}
						</Fragment>
					))}
				</div>

				<div id="what-do-you-need" className="pb-2 md:pb-3 lg:pb-4 xl:pb-6">
					<SevenPathways {...tags} />
				</div>

				{request && weare && (
					<div className="bg-black md:wrapper pb-2 md:pb-3 lg:pb-4 xl:pb-6">
						<h3 className="text-h1-fluid mb-1 font-bold wrapper md:px-0 md:mb-2">
							latest shows
						</h3>
						<div className="lg:flex lg:flex-wrap lg:-ml-1-5">
							<div className="lg:pl-1-5 lg:w-12/24 mb-1 lg:mb-0">
								<MediaPreview {...request} slug="the-request-show" tag="requestshow" />
							</div>
							<div className="lg:pl-1-5 lg:w-12/24">
								<MediaPreview {...weare} slug="wearestraightline" tag="wearestraightline" />
							</div>
						</div>
					</div>
				)}

				<RequestASong {...requestASong} />

				<div className="wrapper relative pb-2 md:pb-3 lg:pb-4 xl:pb-6">
					<h3 className="text-h1-fluid mb-1 font-bold md:mb-2 flex items-baseline">
						articles
						<Link
							className="text-sm-fluid font-bold hover:text-orange ml-1 focus:text-orange outline-none trans trans-color inline-flex items-center"
							to="/articles/"
						>
							<span className="mr-0-5">view all</span>
							<span className="text-teal">
								<BetterIcon icon={rightChevron} className="w-10" />
							</span>
						</Link>
					</h3>

					<div className="relative">
						<Slide
							content={blog}
							showControls={false}
							slidesToShow={3}
							dots
							dotsMd
							gutter="2-5"
							wrapperClassName="md:w-22/24 lg:w-auto"
							btnWrapperClassName="lg:hidden"
							render={item => <ArticlePreview withSubTitle {...item} />}
						/>
					</div>
				</div>

				<div className="lg:wrapper overflow-hidden pb-2 md:pb-3 lg:pb-4 xl:pb-6">
					<div className="relative bg-teal">
						<div className="absolute w-full pin">
							<Image
								fluid={talkToUs.banner}
								outerWrapperClassName="h-full"
								className="h-full"
								imgClassName="object-fit-right"
							/>
						</div>
						<div className=" relative px-1-5 py-2-25 md:w-14/24 lg:w-full lg:flex lg:justify-start  md:block lg:flex md:px-2">
							<h3 className="text-xxl-fluid w-full lg:w-8/24 font-bold md:flex-none mb-1 md:mr-2-75 lg:mr-0 md:leading-0-5 lg:leading-none">
								<div className="block text-teal focus:text-orange outline-none leading-none">
									<span className="text-white lowercase">
										{talkToUs.heading}
									</span>
								</div>
							</h3>

							<div className="lg:w-8/24">
								<div
									className="mb-1 leading-tight text-sm-fluid"
									dangerouslySetInnerHTML={{ __html: talkToUs.body }}
								/>
								<a
									className="block no-underline text-white text-h6-fluid font-bold trans outline-none focus:text-black hover:text-orange trans mb-1"
									href={`mailto:${talkToUs.email}`}
								>
									{talkToUs.email}
								</a>
								<div>
									<div className="flex items-center">
										<a
											className="mr-1 font-bold text-white no-underline outline-none hover:text-orange focus:text-black trans"
											href="https://twitter.com/onstraightline"
										>
											<BetterIcon icon={twitterIcon} className="w-30" />
											<span className="visuallyhidden">Twitter account for we are straightline</span>
										</a>
										<a
											className="mr-1 font-bold text-white no-underline outline-none hover:text-orange focus:text-black trans"
											href="https://www.instagram.com/wearestraightline/"
										>
											<BetterIcon icon={instagramIcon} className="w-30" />
											<span className="visuallyhidden">Instagram account for we are straightline</span>
										</a>
										<a
											className="text-white font-bold no-underline outline-none hover:text-orange focus:text-black trans"
											href="https://www.facebook.com/wearestraightline"
										>
											<BetterIcon icon={facebookIcon} className="w-30" />
											<span className="visuallyhidden">Facebook page for we are straightline</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({
	player: {
		setPageType,
		setAudioBarVisibility,
		setVideoPlayState,
		setAudioPlayState,
		togglePlay,
		setTrack,
		setInUse
	}
}) => ({
	setPageType,
	setAudioBarVisibility,
	setVideoPlayState,
	setAudioPlayState,
	togglePlay,
	setTrack,
	setInUse
})

export default connect(
	mapState,
	mapDispatch
)(IndexPage)

export const pageQuery = graphql`
	query IndexQuery {
		home: craft {
			entry(id: 2) {
				... on Craft_Home {
					title
					body {
						content
					}
					heroImage {
						... on Craft_HeroVolume {
							...optimisedHero
						}
					}
					backgroundVideo {
						url
					}
					latest {
						__typename
						id
						title
						slug
						...relatedBlog
						...relatedAudio
						...relatedVideo
					}
					trending {
						__typename
						id
						title
						slug
						...relatedBlog
						...relatedAudio
						...relatedVideo
					}
					selectedArticles {
						...relatedBlog
					}

					seoTitle
					seoDescription
					socialMetaTitle
					socialMetaDescription
					socialMetaImage {
						... on Craft_ImagesVolume {
							...optimisedImage
						}
					}
				}
			}
		}

		inspiration: craft {
			entry(id: 3) {
				...previewThumb
			}
		}

		advice: craft {
			entry(id: 4) {
				...previewThumb
			}
		}

		organisation: craft {
			entry(id: 289) {
				title
				... on Craft_LandingPagesUseful {
					id
					title
					uri
					slug
					subtitle
					image {
						... on Craft_ImagesVolume {
							...optimisedImage
						}
					}
				}
			}
		}

		blog: craft {
			entries(section: [blog], limit: 3, order: "postDate desc") {
				... on Craft_Blog {
					title
					id
					uri
					postDate
					subtitle
					image {
						... on Craft_ImagesVolume {
							...optimisedImage
						}
					}
				}
			}
		}

		weare: craft {
			entries(section: [weAreStraightline], limit: 1, order: "postDate desc") {
				... on Craft_WeAreStraightline {
					title
					uri
					subtitle
					duration
					mediaSource
					image {
						... on Craft_ImagesVolume {
							...optimisedImage
						}
					}
				}
			}
		}

		request: craft {
			entries(section: [theRequestShow], limit: 1, order: "postDate desc") {
				... on Craft_TheRequestShow {
					title
					duration
					uri
					subtitle
					mediaSource
					image {
						... on Craft_ImagesVolume {
							...optimisedImage
						}
					}
				}
			}
		}

		globals: craft {
			globals {
				requestASong {
					body {
						content
					}
					image {
						... on Craft_ImagesVolume {
							optimisedBanner {
								... on Craft_OptimizedImagesData {
									src
									srcset
									srcWebp
									srcsetWebp
									maxSrcsetWidth
									placeholderWidth
									placeholderHeight
									colorPalette
								}
							}
						}
					}
				}
				talkToUs {
					heading
					body {
						content
					}
					email
				}
				pathways {
					... on Craft_Pathways {
						heading
						text

						tags {
							id
							title
						}
						image {
							... on Craft_ImagesVolume {
								...optimisedImage
							}
						}
					}
				}
			}
		}
	}
`

/*
<div className="wrapper pb-2 md:pb-3 lg:pb-4">
	<div className="md:flex md:flex-wrap md:-ml-1-5">
		<div className="md:pl-1-5 md:w-12/24 flex mb-2 md:mb-0">
			<Fieldnotes {...fieldnotes} />
		</div>
		<div className="md:pl-1-5 md:w-12/24 flex">
			<TalkToUs {...talkToUs} />
		</div>
	</div>
</div>
*/
