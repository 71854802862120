import React, { Component } from 'react'
import { Link } from 'gatsby'
import closeIcon from '@/icons/ui-close.svg'
import Icon from '@/components/utils/Icon'

export default class SevenPathsOverlay extends Component {
	render() {
		const { onClick, tags } = this.props

		return (
			<div className="bg-black absolute flex column min-h-screen w-full p-1">
				<button
					onClick={onClick}
					type="button"
					className="absolute flex items-center pin-t pin-r mt-1 mr-1 p-0-5"
				>
					<span className="mr-0-5 text-xs-fluid">close</span>
					<Icon icon={closeIcon} className="w-20" />
				</button>
				<div className="m-auto mt-1-5 md:mt-auto">
					<h2
						id="overlay-title"
						className="text-h1-fluid font-bold hidden md:block text-center mb-1-5"
					>
						seven pathways.
					</h2>
					<div
						id="overlay-content"
						className="w-full max-w-900 flex flex-wrap md:justify-center text-center"
					>
						{tags.map(({ id, title, uri, icon }) => (
							<Link
								key={id}
								to={uri}
								onClick={() => setTimeout(() => window.scrollTo(0, 0))}
								className="w-12/24 md:w-6/24 md:pb-2 flex flex-wrap justify-center p-0-5 text-teal hover:text-orange focus:text-orange outline-none trans trans-color"
							>
								<span className="mb-0-5 block w-full">
									<Icon
										icon={icon}
										className="mx-auto"
										style={{
											height: '4rem',
											maxWidth: '5rem'
										}}
									/>
								</span>
								<span className="text-md-fluid font-bold text-white block w-full text-center self-end mb-0-5 md:mb-1 mt-0-25">
									{title}
								</span>
							</Link>
						))}
					</div>
				</div>
			</div>
		)
	}
}
