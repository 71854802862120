import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Image from '@/components/utils/Image'
import BigPlayPause from '@/components/utils/BigPlayPause'
import Tags from '@/components/Tags/Tags'

export default class LatestContent extends Component {
	shouldComponentUpdate = () => false

	render() {
		const {
			tags,
			duration,
			title,
			image,
			uri,
			mediaSource,
			setVideoPlayState,
			type,
			setTrack,
			setAudioBarVisibility,
			setInUse,
			item,
			togglePlay,
			subtitle
		} = this.props

		return (
			<div className="md:wrapper pb-2 xl:pb-5">
				<div className="flex flex-wrap flex-col-reverse md:flex-row">
					<div className="w-full md:w-12/24 p-1 md:pl-0 lg:pr-3">
						<p className="text-md-fluid mb-1">latest content</p>
						<h2 className="mb-1-5 text-h2-fluid font-bold lowercase">
							<Link
								to={uri}
								className="leading-none hover:text-orange focus:text-orange trans trans-color outline-none"
								onClick={() => {
									if (type === 'Craft_Video') {
										setVideoPlayState(true)
									}
								}}
							>
								{title}
							</Link>
						</h2>

						{subtitle && <p className="text-xs-fluid mb-1">{subtitle}</p>}
						{duration && (
							<p className="text-grey-light mb-0-25 text-time-fluid">
								{duration}
							</p>
						)}
						<Tags tags={tags} />
					</div>
					<div className="relative w-full md:w-12/24">
						<div className="dimmer">
							<Image fluid={image} />

							<div className="absolute-center z-20">
								<BigPlayPause
									to={uri}
									onClick={() => {
										// e.preventDefault()

										if (type === 'Craft_Video') {
											setVideoPlayState(true)
										} else if (type === 'Craft_Audio') {
											// setAudioPlayState(true)
											setTrack(item)
											setInUse(true)
											setAudioBarVisibility(true)
											togglePlay()
										}
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

/* eslint-disable */
LatestContent.propTypes = {
	title: PropTypes.string.isRequired,
	duration: PropTypes.string,
	mediaSource: PropTypes.string,
	tags: PropTypes.array.isRequired,
	uri: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	image: PropTypes.object.isRequired
}
/* eslint-enable */
