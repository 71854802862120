import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import rightChevron from '@/icons/ui-right-chevron.svg'
import Icon from '@/components/utils/Icon'
import Image from '@/components/utils/Image'

export default class RequestASong extends Component {
	render() {
		const { banner, body } = this.props

		return (
			<div className="lg:wrapper overflow-hidden pb-2 md:pb-3 lg:pb-4 xl:pb-6">
				<div className="relative">
					<div className="absolute w-full pin">
						<Image
							fluid={banner}
							outerWrapperClassName="h-full"
							className="h-full"
							imgClassName="object-fit-right"
						/>
					</div>
					<div className=" relative px-1-5 py-2-25 md:w-14/24 lg:w-full flex justify-end lg:justify-start  md:block lg:flex md:px-2">
						<h3 className="text-xxl-fluid w-12/24 md:w-full lg:w-8/24 font-bold md:flex-none md:mb-1 md:mr-2-75 lg:mr-0 md:leading-0-5 lg:leading-none">
							<Link
								className="block text-teal focus:text-orange outline-none leading-none"
								to="/request-a-song/"
							>
								<span className="text-currentColor relative block md:inline">
									<span
										className="absolute block md:hidden mr-1 my-auto pin-y w-23/24 bg-white"
										style={{ height: 4, right: '100%' }}
									/>
									request
								</span>{' '}
								<span className="text-white">a song</span>
							</Link>
						</h3>

						<div className="hidden md:block lg:w-8/24">
							<div
								className="mb-1 leading-tight text-sm-fluid"
								dangerouslySetInnerHTML={{ __html: body }}
							/>
							<Link
								className="text-sm-fluid font-bold hover:text-orange focus:text-orange outline-none trans trans-color inline-flex items-center"
								to="/request-a-song/"
							>
								<span className="mr-0-5">request song</span>
								<span className="text-teal h-full">
									<Icon icon={rightChevron} />
								</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

RequestASong.propTypes = {
	body: PropTypes.string.isRequired,
}
